import React, { FC, useEffect, useState } from "react";
import "./VerifyAccount.css";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

interface VerifyAccountProps {}

interface Response {
  data: string;
  success: boolean;
  timestamp: string;
}

const VerifyAccount: FC<VerifyAccountProps> = () => {
  const [uuid, setUuid] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [response, setResponse] = useState<Response>();

  const [searchParams] = useSearchParams();
  const code = searchParams.get("token");
  useEffect(() => {
    axios
      .get(
        `https://apis.nuhman.com/api/auth/registrationConfirmation?token=` +
          code
      )
      .then((res) => {
        setResponse(res?.data);
      })
      .catch((err) => {
        console.log("Error 404: Resource not found");
        // Handle 404 error (e.g., show an error message to the user
        const responseData: Response = {
          data: "Registration Email Verification Failed",
          success: false,
          timestamp: new Date().toISOString(),
        };
        setResponse(responseData);
      });
  }, [code]);
  //apis.nuhman.com/api/auth/registrationConfirmation?token=73706d41-3bff-408c-aab3-84be8c021076

  return (
    <>
      <Helmet>
        <title>{"Nuhman.com - Verify Account"}</title>
        <meta name="description" content={"Verify Account"} />
        <meta property="og:title" content={"Nuhman.com - Verify Account"} />
        <meta property="og:description" content={"Verify Account"} />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="overlay position-relative bg-light rresult">
              <div className="mb-3 rresult col-lg-12">
                <h3 className=" mg">{"Email Verification"}</h3>

                <div>
                  {response && (
                    <>
                      {" "}
                      <br />
                      <p>{response.data}</p>
                      {response.success && (
                        <p>
                          Please <a href="/#/login">Login</a>
                        </p>
                      )}
                      {!response.success && (
                        <p>
                          Please <a href="/#/register">Register</a>
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <p>**</p>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default VerifyAccount;
