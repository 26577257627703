import React, { useState } from "react";
import { Helmet } from "react-helmet";

// Helper function to convert integer to Roman numeral
const intToRoman = (num: number): string => {
  if (num <= 0) return ""; // Roman numerals do not have a representation for 0 or negative numbers
  const values: number[] = [
    1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1,
  ];
  const symbols: string[] = [
    "M",
    "CM",
    "D",
    "CD",
    "C",
    "XC",
    "L",
    "XL",
    "X",
    "IX",
    "V",
    "IV",
    "I",
  ];

  let result = "";
  for (let i = 0; i < values.length; i++) {
    while (num >= values[i]) {
      result += symbols[i];
      num -= values[i];
    }
  }
  return result;
};

const RomanConverter: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"single" | "range">("single");
  const [number, setNumber] = useState<number | "">("");
  const [romanNumeral, setRomanNumeral] = useState<string>("");
  const [rangeStart, setRangeStart] = useState<number>(0);
  const [rangeEnd, setRangeEnd] = useState<number>(10);
  const [romanTable, setRomanTable] = useState<
    { num: number; roman: string }[]
  >([]);

  // Handle input changes for single number
  const handleSingleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0) {
      setNumber(value);
    } else {
      setNumber("");
    }
  };

  // Handle conversion for a single number
  const handleSingleConvert = () => {
    if (typeof number === "number") {
      const roman = intToRoman(number);
      setRomanNumeral(roman);
    }
  };

  // Handle range input change
  const handleRangeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "start" | "end"
  ) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value)) {
      if (type === "start") setRangeStart(value);
      else setRangeEnd(value);
    }
  };

  // Handle conversion for a range of numbers
  const handleRangeConvert = () => {
    const results: { num: number; roman: string }[] = [];
    for (let i = rangeStart; i <= rangeEnd; i++) {
      results.push({ num: i, roman: intToRoman(i) });
    }
    setRomanTable(results);
  };

  return (
    <>
      <Helmet>
        <title>{"Nuhman.com - Integer to Roman Conversion"}</title>
        <meta
          name="description"
          content={"Generate Roman numbers for a range of integer numbers"}
        />
        <meta
          property="og:title"
          content={"Nuhman.com - Integer to Roman Number Conversion"}
        />
        <meta
          property="og:description"
          content={"Generate Roman numericals from integers"}
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="overlay position-relative bg-light rresult">
              <div className="mb-3 rresult col-lg-12">
                <div style={{ padding: "20px", textAlign: "center" }}>
                  <h2>Integer to Roman Numeral Converter</h2>

                  <div style={{ marginBottom: "20px" }}>
                    <button
                      onClick={() => setActiveTab("single")}
                      style={{
                        padding: "10px 20px",
                        marginRight: "10px",
                        color: "white",
                        border: "0px",
                        backgroundColor:
                          activeTab === "single" ? "#f7888b" : "#ED1C24",
                      }}
                    >
                      Single Conversion
                    </button>
                    <button
                      onClick={() => setActiveTab("range")}
                      style={{
                        padding: "10px 20px",
                        color: "white",
                        border: "0px",
                        backgroundColor:
                          activeTab === "range" ? "#f7888b" : "#ED1C24",
                      }}
                    >
                      Range Conversion
                    </button>
                  </div>

                  {activeTab === "single" && (
                    <div>
                      <input
                        type="number"
                        placeholder="Enter a number"
                        value={number}
                        onChange={handleSingleChange}
                        min="1"
                        style={{
                          padding: "10px",
                          fontSize: "16px",
                          width: "200px",
                        }}
                      />
                      <br />
                      <button
                        onClick={handleSingleConvert}
                        style={{
                          marginTop: "10px",
                          padding: "10px 20px",
                          fontSize: "16px",
                          color: "white",
                          border: "0px",
                          backgroundColor: "#ED1C24",
                        }}
                      >
                        Convert to Roman
                      </button>
                      {romanNumeral && (
                        <div style={{ marginTop: "20px", fontSize: "24px" }}>
                          <strong>Roman Numeral: {romanNumeral}</strong>
                        </div>
                      )}
                    </div>
                  )}

                  {activeTab === "range" && (
                    <div>
                      <div>
                        <input
                          type="number"
                          placeholder="Range start"
                          value={rangeStart}
                          onChange={(e) => handleRangeChange(e, "start")}
                          style={{
                            padding: "10px",
                            fontSize: "16px",
                            width: "120px",
                            marginRight: "10px",
                          }}
                        />
                        <input
                          type="number"
                          placeholder="Range end"
                          value={rangeEnd}
                          onChange={(e) => handleRangeChange(e, "end")}
                          style={{
                            padding: "10px",
                            fontSize: "16px",
                            width: "120px",
                          }}
                        />
                      </div>
                      <br />
                      <button
                        onClick={handleRangeConvert}
                        style={{
                          marginTop: "10px",
                          padding: "10px 20px",
                          fontSize: "16px",
                          color: "white",
                          border: "0px",
                          backgroundColor: "#ED1C24",
                        }}
                      >
                        Generate Roman Numerals for Range
                      </button>
                      {romanTable.length > 0 && (
                        <table
                          style={{
                            marginTop: "20px",
                            borderCollapse: "collapse",
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "10px",
                                }}
                              >
                                Integer
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "10px",
                                }}
                              >
                                Roman Numeral
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {romanTable.map((item) => (
                              <tr key={item.num}>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    padding: "10px",
                                  }}
                                >
                                  {item.num}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    padding: "10px",
                                  }}
                                >
                                  {item.roman}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <p>**</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RomanConverter;
