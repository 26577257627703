import React, { ChangeEvent, FC, useEffect, useState } from "react";
import "./Dashboard.css";
import CategorySlider1 from "../CategorySlider1/CategorySlider1";
import CategorySlider2 from "../CategorySlider2/CategorySlider2";
import FeaturedSlider from "../FeaturedSlider/FeaturedSlider";
import MainSlider from "../MainSlider/MainSlider";
import Sidebar from "../Sidebar/Sidebar";
import TopSlider from "../TopSlider/TopSlider";
import Logout from "../Logout/Logout";
import ReactQuill from "react-quill";
import {
  admission,
  ArticleCategory,
  college,
  CollegeWebsiteAdderDAO,
  ContentType,
  course,
  educationCategory,
  state,
  State,
  techCategory,
} from "../../interfaces";
import { apiGet, apiPost, apiPostWithFile } from "../../api/apiActions";
import { getToken, removeSessionToken } from "../../store/sessionManager";
import { useNavigate } from "react-router-dom";
import { Country as ctry } from "../../interfaces";
import axios from "axios";

interface DashboardProps {}

interface YoutubeChannelDetail {
  id: Number;
  userId: string;
  name: string;
  channelId: string;
  enabled: boolean; // New enabled flag
  thumbnail: string;
  channelName: string;
  subscriberCount: string;
}

const Dashboard: FC<DashboardProps> = () => {
  const [showAddArticle, setShowAddArticle] = useState<boolean>(false);
  const [showAddWebsite, setShowAddWebsite] = useState<boolean>(false);
  const [collegeId, setCollegeId] = useState("0");
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState("");
  const [error, setError] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [pageId, setPageId] = useState("");
  const [countries, setCountries] = useState<ctry[]>();
  const [states, setStates] = useState<State[]>();
  const [admissions, setAdmissions] = useState<admission[]>();
  const [colleges, setColleges] = useState<college[]>();
  const [collegeWebsite, setCollegeWebsite] =
    useState<CollegeWebsiteAdderDAO>();
  const [courses, setCourses] = useState<course[]>();
  const [contentTypes, setContentTypess] = useState<ContentType[]>();
  const [educationCategories, setEducationCategories] =
    useState<educationCategory[]>();
  const [techCategories, setTechCategories] = useState<techCategory[]>();

  const [imageName, setImageName] = useState("");
  const reader = new FileReader();

  const [articleCategories, setArticleCategories] =
    useState<ArticleCategory[]>();
  const navigate = useNavigate();

  async function handleDeleteCrumb() {
    let url = `/apis/education-data/article-category`;
    const response = await apiGet(url);
    if (response?.status === 401) {
      //place your reentry code
      removeSessionToken();
      navigate("/login");
    } else {
      setArticleCategories(response?.data);
    }
  }

  async function handleGet() {
    let url = `/apis/education-data/college-add?collegeId=` + collegeId;
    const response = await apiGet(url);
    setCollegeWebsite(response?.data);
    setCollegeId(response?.data?.college?.id);
  }

  useEffect(() => {
    if (getToken() == null) {
      removeSessionToken();
      navigate("/login");
    }
    handleDeleteCrumb();

    handleGet();

    axios
      .get(url + `apis/nuhman/tech/categories`)
      .then((res) => {
        setTechCategories(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + `apis/education/content-types`)
      .then((res) => {
        setContentTypess(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const module = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const url = `https://apis.nuhman.com/`;
  function addArticleBox(event: React.MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    setShowAddArticle(true);
  }

  function addCollegeWebsite(event: React.MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    setShowAddArticle(false);
    setShowAddWebsite(true);
  }

  async function postWebsite(formData: FormData) {
    const response = await apiPost(
      "/apis/education-data/college-add",
      formData
    );
    //return response;
  }

  async function postArticle(formData: FormData) {
    const response = await apiPost("/apis/nuhman/articles", formData);
  }

  async function postArticleImage(
    formData: FormData,
    width: number,
    height: number
  ) {
    const response = await apiPostWithFile(
      "/apis/education-data/article/image?width=" + width + "&height=" + height,
      formData
    );
    setImageName(response?.data);
  }

  function submitWebsite(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const hasName = formData.has("website");
    const hastwebsite = formData.has("tcollege");
    if (!hasName && hastwebsite) {
      let tcoll = formData.get("tcollege");
      formData.set("website", tcoll || "");
    }

    postWebsite(formData);
    event.currentTarget.reset();
    handleGet();
  }

  function submitArticle(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    formData.set("description", value);
    //formData.set("image", "a" + imageName + ".JPEG");
    //formData.set("image500x280", "b" + imageName + ".JPEG");
    //formData.set("image100x100", "c" + imageName + ".JPEG");

    const response = postArticle(formData);
    //throw new Error("Function not implemented.");
    event.currentTarget.reset();
  }

  function addState(event: ChangeEvent<HTMLSelectElement>): void {
    axios
      .get(url + `apis/education/states?countryId=` + event.target.value)
      .then((res) => {
        setStates(res?.data);
      })
      .catch((err) => console.log(err));
  }

  function populateData(event: ChangeEvent<HTMLSelectElement>): void {
    axios
      .get(url + `apis/education/admissions?stateid=` + event.target.value)
      .then((res) => {
        setAdmissions(res?.data.admissions);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        url +
          `apis/education/colleges?pageSize=500&stateId=` +
          event.target.value
      )
      .then((res) => {
        setColleges(res?.data?.colleges);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        url +
          `apis/education/courses?pageSize=600&+stateid=` +
          event.target.value
      )
      .then((res) => {
        setCourses(res?.data?.courses);
      })
      .catch((err) => console.log(err));
  }

  function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
    const filesList = event.target.files;
    if (filesList && filesList.length > 0) {
      reader.readAsDataURL(filesList[0]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e?.target?.result as string;
        image.onload = () => {
          const { height, width } = image;
          console.log(height + "   ----  " + width);
          const formData = new FormData();
          formData.append("file", filesList[0]);
          const response = postArticleImage(formData, width, height);
        };
      };
    }
  }

  return (
    <>
      <TopSlider />

      <div className="container">
        <div className="row aright">
          <Logout />
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="bg-light rresultser">
              <div className="mb-3 rresult">
                <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                  <h2>Test</h2>
                  <span>
                    <a href="#" onClick={addArticleBox}>
                      Add Article
                    </a>
                  </span>
                  {collegeWebsite && (
                    <>
                      <span>
                        <a href="#" onClick={addCollegeWebsite}>
                          Add Website
                        </a>
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 mmargin">
            <div className="bg-light rresults">
              <div className="mb-3 rresult">
                <div className=" bg-light">
                  {showAddArticle && (
                    <>
                      <div>
                        <h3>Add Article</h3>

                        <form onSubmit={submitArticle}>
                          <div className="control-group wid">
                            <p>
                              Select Article Category:{" "}
                              <select name="articleCategoryId" className=" p-2">
                                <option value={0}>Select</option>
                                {techCategories?.map(
                                  (ctry: techCategory, index) => (
                                    <option key={index} value={ctry.id}>
                                      {ctry.name}
                                    </option>
                                  )
                                )}
                              </select>
                            </p>
                          </div>

                          {admissions && (
                            <>
                              {" "}
                              <div className="control-group wid">
                                <p>
                                  Select Admission:{" "}
                                  <select
                                    name="admissionId"
                                    onSelect={populateData}
                                    className=" p-2"
                                  >
                                    <option value={0}>Select Admission</option>
                                    {admissions?.map(
                                      (ctry: admission, index) => (
                                        <option key={index} value={ctry.id}>
                                          {ctry.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </p>
                              </div>
                            </>
                          )}
                          {courses && (
                            <>
                              {" "}
                              <div className="control-group wid">
                                <p>
                                  Select Course:{" "}
                                  <select
                                    name="courseId"
                                    onSelect={populateData}
                                    className=" p-2 mwd"
                                  >
                                    <option value={0}>Select Course</option>
                                    {courses?.map((ctry: course, index) => (
                                      <option key={index} value={ctry.id}>
                                        {ctry.name}
                                      </option>
                                    ))}
                                  </select>
                                </p>
                              </div>
                            </>
                          )}

                          {colleges && (
                            <>
                              {" "}
                              <div className="control-group wid">
                                <p>
                                  Select College:{" "}
                                  <select
                                    name="collegeId"
                                    onSelect={populateData}
                                    className=" p-2"
                                  >
                                    <option value={0}>Select College</option>
                                    {colleges?.map((ctry: college, index) => (
                                      <option key={index} value={ctry.id}>
                                        {ctry.name}
                                      </option>
                                    ))}
                                  </select>
                                </p>
                              </div>
                            </>
                          )}
                          <div className="control-group wid">
                            <input
                              type="text"
                              className="form-control p-4"
                              id="title"
                              placeholder="Title"
                              onChange={(e) => setTitle(e.target.value)}
                              value={title}
                              name="title"
                              required
                              data-validation-required-message="Please enter a title"
                            />
                            <p className="help-block text-danger"></p>
                          </div>
                          <div className="control-group wid">
                            <input
                              type="text"
                              className="form-control p-4"
                              id="tags"
                              placeholder="Tags"
                              name="tags"
                              onChange={(e) => setTags(e.target.value)}
                              value={tags}
                            />
                            <p className="help-block text-danger">{error}</p>
                          </div>

                          <div className="control-group wid">
                            <p>
                              {" "}
                              Image;
                              <input
                                id="file3"
                                type="file"
                                name="image"
                                onChange={handleFileChange}
                              />
                            </p>
                            <p className="help-block text-danger">
                              {imageName && (
                                <>
                                  <img
                                    src={
                                      "https://Nuhman.com/images/c" +
                                      imageName +
                                      ".JPEG"
                                    }
                                    width={100}
                                    height={100}
                                  />
                                </>
                              )}
                            </p>
                          </div>

                          <div className="control-group wid txarea">
                            {" "}
                            <ReactQuill
                              theme="snow"
                              value={value}
                              modules={module}
                              onChange={setValue}
                            />
                          </div>
                          <div>
                            <button
                              className="btn btn-primary font-weight-semi-bold px-4"
                              style={{ height: 50 }}
                              type="submit"
                              id="sendMessageButton"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  )}

                  {showAddWebsite && (
                    <>
                      <div>
                        <h3>Add Website</h3>

                        <form onSubmit={submitWebsite}>
                          {collegeWebsite && (
                            <>
                              {" "}
                              <div className="control-group wid">
                                <input
                                  type="hidden"
                                  name="collegeId"
                                  value={collegeWebsite?.college?.id}
                                />
                                {collegeWebsite.college?.name}
                              </div>
                              {collegeWebsite?.websites?.map(
                                (ctry: string, index) => (
                                  <div className="div2" key={index}>
                                    <p>
                                      <input
                                        type="checkbox"
                                        name="website"
                                        value={ctry}
                                        defaultChecked={index === 0}
                                      />
                                      <a href={ctry} target="_blank">
                                        &nbsp; {ctry}
                                      </a>
                                    </p>
                                  </div>
                                )
                              )}
                              <div>
                                <input type="text" name="tcollege" />
                              </div>
                              <div>
                                <button
                                  className="btn btn-primary font-weight-semi-bold px-4"
                                  style={{ height: 50 }}
                                  type="submit"
                                  id="sendMessageButtons"
                                >
                                  Submit
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeaturedSlider />
      <CategorySlider1 />
      <CategorySlider2 />
      <Sidebar isLoading={true} />
    </>
  );
};

export default Dashboard;
