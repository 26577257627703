import React, { useState } from "react";
import { DateTime } from "luxon";
import DateTimePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import "moment-timezone";

const TimeZoneConverter: React.FC = () => {
  const [sourceTimeZone, setSourceTimeZone] = useState<string>("UTC");
  const [targetTimeZone, setTargetTimeZone] = useState<string>("UTC");
  const [dateTime, setDateTime] = useState<DateTime | null>(DateTime.local());
  const [convertedTime, setConvertedTime] = useState<string>("");

  // Get all available time zones using moment-timezone
  const timeZones = moment.tz.names();

  // Handle time zone conversion
  const handleConvert = () => {
    if (!dateTime) {
      alert("Please select a valid date and time.");
      return;
    }

    // Convert dateTime in the source time zone to the target time zone
    const sourceDateTime = dateTime.setZone(sourceTimeZone);
    const targetDateTime = sourceDateTime.setZone(targetTimeZone);

    // Format the result
    setConvertedTime(targetDateTime.toLocaleString(DateTime.DATETIME_FULL));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <div className="overlay position-relative bg-light rresult">
            <div className="mb-3 rresult col-lg-12">
              <div style={{ padding: "20px" }}>
                <h2>Time Zone Converter</h2>

                <div style={{ marginBottom: "10px" }}>
                  <label>Select Date and Time:</label>
                  <DateTimePicker
                    value={dateTime?.toJSDate()}
                    onChange={(value: moment.Moment | string) => {
                      if (moment.isMoment(value)) {
                        const date = value.toDate();
                        setDateTime(DateTime.fromJSDate(date));
                      }
                    }}
                    inputProps={{ placeholder: "Select Date and Time" }}
                  />
                </div>

                <div style={{ marginBottom: "10px" }}>
                  <label>From Time Zone:</label>
                  <select
                    value={sourceTimeZone}
                    onChange={(e) => setSourceTimeZone(e.target.value)}
                    style={{ marginLeft: "10px" }}
                  >
                    {timeZones.map((tz) => (
                      <option key={tz} value={tz}>
                        {tz}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ marginBottom: "10px" }}>
                  <label>To Time Zone:</label>
                  <select
                    value={targetTimeZone}
                    onChange={(e) => setTargetTimeZone(e.target.value)}
                    style={{ marginLeft: "10px" }}
                  >
                    {timeZones.map((tz) => (
                      <option key={tz} value={tz}>
                        {tz}
                      </option>
                    ))}
                  </select>
                </div>

                <button onClick={handleConvert}>Convert Time</button>

                {convertedTime && (
                  <div style={{ marginTop: "20px" }}>
                    <h4>Converted Time:</h4>
                    <p>{convertedTime}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <p>**</p>
        </div>
      </div>
    </div>
  );
};

export default TimeZoneConverter;
